/* eslint-disable curly */
/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { EXPERIENCES } from './constants';
import { doc, Firestore, setDoc, getDoc } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AlertService } from '../../shared/helpers/alert.service';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	item: Observable<any>;
	userInfoRoute: string;

	private _user: any;
	private _currentStep = 2;
	private _userInfo: any = { interests: EXPERIENCES };

	constructor(
		private firestore: Firestore,
		private alertService: AlertService
	) {
		const user = window.localStorage.getItem('plaey-user');
		if (user) {
			this.user = JSON.parse(user);
			if (!this._userInfo) this.getUserInfo();
		}
	}

	//#region props GETTERS & SETTERS
	get userInfo() { return this._userInfo; }
	get currentStep() { return this._currentStep; }
	get user() { return this._user; }
	set user(value: any) {
		this._user = value;
		window.localStorage.setItem('plaey-user', JSON.stringify(value));
		this.userInfoRoute = `users/${this.user?.uid}`;
	}
	//#endregion

	updateUserInfo(values: any) {
		this._userInfo = { ...this._userInfo, ...values };
	}

	changeStep = async (value: number) => {
		try {
			if (this.currentStep === 2) {
				const location = JSON.parse(localStorage.getItem('plaey-geolocation'));
				await setDoc(
					doc(
						this.firestore,
						this.userInfoRoute
					),
					{ ...this.userInfo, location, email: this.user?.email }
				);
			}
			this._currentStep = value;
		} catch (err) {
			console.error(err);
			await this.alertService.show('Something went wrong. Please try again.');
		}
	};

	getUserInfo = async () => {
		let data: any;
		try {
			if (!this.user) return data;
			const route = `users/${this.user?.uid}`;
			const docRef = doc(this.firestore, route);
			data = (await getDoc(docRef))?.data();
			if (data) this._userInfo = data;
			console.log('user-info', this.userInfo);
		} catch (err) {
			if (!environment.production) console.error(err);
			// await this.alertService.show('Something went wrong. Please try again.');
		}

		return data;
	};
}
