/* eslint-disable curly */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/user-information/helpers/user.service';
import { CompassService } from 'src/app/compass/helpers/compass.service';

@Injectable({
	providedIn: 'root'
})
export class UserInformationGuard implements CanLoad, CanActivate {
	constructor(
		private router: Router,
		private userState: UserService,
		private compassState: CompassService
	) { }

	canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.userState.getUserInfo()
			.then(v => Boolean(v) || this.router.parseUrl('/user-information'));
		// .then(async v => {
		// 	if(Boolean(v)) return true;
		// 	else {
		// 		const assessmentExists = await this.compassState.getAssessment();
		// 		// console.log(assessmentExists);
		// 		return this.router.parseUrl(`/compass${assessmentExists ? '/results': ''}`);
		// 	}
		// });
	}

	canLoad(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.userState.getUserInfo()
			.then(v => Boolean(v) || this.router.parseUrl('/user-information'));
		// .then(async v => {
		// 		if(Boolean(v)) return true;
		// 		else {
		// 			const assessmentExists = await this.compassState.getAssessment();
		// 			// console.log(assessmentExists);
		// 			return this.router.parseUrl(`/compass${assessmentExists ? '/results': ''}`);
		// 		}
		// 	});
	}
}
