/* eslint-disable curly */
/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { collection, doc, Firestore, getDocs, setDoc, updateDoc, addDoc, getDoc } from '@angular/fire/firestore';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { AlertService } from '../../shared/helpers/alert.service';
import { ASSESSMENT_QUESTIONS } from './assessmentQuestions';
import { CATEGORIES } from './categories';
import { UserService } from '../../user-information/helpers/user.service';

@Injectable({
	providedIn: 'root'
})
export class CompassService {
	currentAssessmentId: any;
	assessment: any;
	private _currentStep = 0;
	private _assessmentResponses = {};

	constructor(
		private firestore: Firestore,
		private alertService: AlertService,
		private userState: UserService
	) {
		if (!this.assessment) this.getAssessment();
	}

	get currentStep() { return this._currentStep; }
	get assessmentResponses() { return this._assessmentResponses; }

	changeStep(value: number) {
		this._currentStep = value;
	}

	getPublicAssessment = async () => {
		const assessmentId = sessionStorage.getItem('publicAssessmentId');
		const route = `publicAssessments/${assessmentId}`;
		return (await getDoc(doc(this.firestore, route)))?.data();
	};

	linkPublicAssessment = async () => {
		const data = await this.getPublicAssessment();
		if (data) {
			this.saveAssessmentResponses(data.responses);
			sessionStorage.removeItem('publicAssessmentId');
		}
	};

	saveAssessmentResponses = async (value: any) => {
		this._assessmentResponses = value;
		const now = moment();
		try {
			const route = this.userState.user ? `assessments/${this.userState.user.uid}/globals` : 'publicAssessments';

			if (this.currentAssessmentId)
				await updateDoc(
					doc(this.firestore, route, `${this.currentAssessmentId}`),
					{
						updatedAt: now.unix(),
						responses: this.assessmentResponses,
						...this.getScores()
					}
				);
			else {
				const result = await addDoc(collection(this.firestore, route), {
					createdAt: now.unix(),
					updatedAt: now.unix(),
					date: now.format('MM/YYYY'),
					responses: this.assessmentResponses,
					...this.getScores()
				});
				if(!this.userState.user) sessionStorage.setItem('publicAssessmentId', result.id);
			}
		} catch (err) {
			console.error(err);
			await this.alertService.show('Something went wrong. Please try again.');
		}
	};

	getAssessment = async () => {
		let docRef: any;
		let data: any;

		try {
			if (this.userState.user) {
				const route = `assessments/${this.userState.user.uid}/globals`;
				const collectionRef = collection(this.firestore, route);
				const docSnap = await getDocs(collectionRef);

				docSnap.forEach(document => {
					docRef = document;
				});
				// THE LAST ONE - .length does not exist
				if (docRef?.id) {
					this.currentAssessmentId = docRef.id;
					const { responses, ...attrs } = docRef.data();
					this._assessmentResponses = responses;
					data = responses;
					this.assessment = attrs;
				}
			}
			else {
				const assessment = await this.getPublicAssessment();
				if (assessment) {
					const { responses, ...attrs } = assessment;
					this._assessmentResponses = responses;
					data = responses;
					this.assessment = attrs;
				}
			}
		} catch (err) {
			if (!environment.production) console.error(err);
		}
		return data;
	};

	getScores() {
		if (!Object.keys(this.assessmentResponses).length) return;
		let categoriesSum = 0;
		const responses = this.assessmentResponses;
		let allQuestions = [];
		ASSESSMENT_QUESTIONS.forEach(x => allQuestions = [...allQuestions, ...x]);

		const scoresByCategory = {};

		for (const category of CATEGORIES) {
			const questions = allQuestions.filter(x => x.category === category.key);
			const responseValues = questions.map(x => {
				let value = responses[x.key];
				if (x.reverse) {
					if (value === 0) value = 5;
					else if (value === 1) value = 4;
					else if (value === 2) value = 3;
					else if (value === 3) value = 2;
					else if (value === 4) value = 1;
					else value = 1;
				}
				return value * 2;
			});
			const avgValue = responseValues.reduce((old, val) => old + val) / questions.length;
			scoresByCategory[category.key] = {
				result: avgValue,
				score: avgValue <= 3 ? 'Languishing' : (avgValue <= 6.9 ? 'Functioning' : 'Flourishing')
			};
			categoriesSum = categoriesSum + avgValue;
		}

		const categoriesAvg = categoriesSum / CATEGORIES.length;

		return {
			result: Number(categoriesAvg.toFixed(2)),
			score: categoriesAvg <= 3 ? 'Languishing' : (categoriesAvg <= 6.9 ? 'Functioning' : 'Flourishing'), // AVERAGE
			...scoresByCategory
		};
	}
}
