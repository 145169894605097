export const EXPERIENCES = [
	{
		key: 'nature',
		title: 'Nature',
		description: 'Hiking, Grounding, Rock Climbing, etc...',
		order: 1
	},
	{
		key: 'sound',
		title: 'Sound',
		description: 'Concerts, Sound baths, Music lessons, etc...',
		order: 2
	},
	{
		key: 'creativity',
		title: 'Creativity',
		description: 'Painting, Drawing, Acting, etc...',
		order: 3
	},
	{
		key: 'nourishment',
		title: 'Nourishment',
		description: 'Cooking, Tastings, Horticulture, etc...',
		order: 4
	},
	{
		key: 'movement',
		title: 'Movement',
		description: 'Running, Yoga, Dancing, etc...',
		order: 5
	},
	{
		key: 'spirituality',
		title: 'Spirituality',
		description: 'Meditation, Mindfulness, Reiki, etc...',
		order: 6
	}
];

export const STEPS = [
	{
		route: 'basic',
		title: `Let's get to know each other`,
	},
	{
		route: 'habits',
		title: 'Tell us about your habits'
	},
	{
		route: 'interests',
		title: 'What type of experiences are you most interested in?'
	}
];

export const RACES = [
	{ label: 'Asian', value: 'asian' },
	{ label: 'Hispanic/LatinX', value: 'hispanic' },
	{ label: 'Middle Eastern', value: 'eastern' },
	{ label: 'Black or African American', value: 'african' },
	{ label: 'Native Hawaiian or Pacific Islander', value: 'hawaiian' },
	{ label: 'White', value: 'white' },
	{ label: 'Other', value: 'other' },
	{ label: 'Prefer not to answer', value: 'unknown' }
];

export const GENDERS = [
	{ label: 'Male', value: 'M' },
	{ label: 'Female', value: 'F' },
	{ label: 'Transgender Female', value: 'TF' },
	{ label: 'Transgender Male', value: 'TM' },
	{ label: 'Non-binary', value: 'NB' },
	{ label: 'Other', value: 'O' },
	{ label: 'Prefer not to answer', value: 'U' }
];

export const SLEEP_HOURS = [
	{ label: '8 +', value: '8+' },
	{ label: '6 - 7', value: '6-7' },
	{ label: '4 - 5', value: '4-5' },
	{ label: 'Less than 4', value: '4-' }
];

export const DAY_HABITS = [
	{ label: 'Mostly indoors', value: 1 },
	{ label: 'Indoors but I go out on a regular basis', value: 2 },
	{ label: 'I spend the better part of the day on foot', value: 3 },
	{ label: 'Manual labor', value: 4 },
	{ label: 'Other', value: 0 }
];
