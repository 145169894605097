// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCzN-d9GQnsxQeBo4qbvsGu_rJx-PtTpDI",
    authDomain: "plaeyapp.firebaseapp.com",
    projectId: "plaeyapp",
    storageBucket: "plaeyapp.appspot.com",
    messagingSenderId: "744757197761",
    appId: "1:744757197761:web:62b0d468d8ccc97968cd40",
    measurementId: "G-XF7QRKD8FH",
  },
  GTMId: 'GTM-5GL4W5W',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
