import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
	providedIn: 'root'
})
export class AlertService {

	constructor(
		private alertController: AlertController
	) { }

	show = async (message: string, header = 'Error') => {
		const alert = await this.alertController.create({
			header,
			message,
			buttons: [
				{
					text: 'Ok',
					role: 'cancel',
					cssClass: 'secondary',
					id: 'ok-button',
				}
			]
		});

		await alert.present();
		return alert;
	};
}
