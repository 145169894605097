export const CATEGORIES = [
	{
		key: 'connection',
		title: 'Connection',
		img: '/assets/icon/connection.svg',
		imgActive: '/assets/icon/connection_contrast.svg',
		description: 'is the feeling of being seen, acknowledged, and understood by others.',
		scoreSummary: {
			languishing: `You feel disconnected from the important people in your life, do not often feel
			like you belong to a stable and supportive community. You rarely feel like you help
			make your community a better place for people.`,
			functioning: `You sometimes feel connected to people in your life, but not always.
			You struggle sometimes to feel like your work or activities improve the lives of the
			people around you, and sometimes it’s difficult to feel a trusting relationship with
			other people.`,
			flourishing: `You feel deeply connected to people in your life, like your community benefits
			from your presence, and like you can trust the important people in your life.`
		}
	},
	{
		key: 'flexibility',
		title: 'Flexibility',
		img: '/assets/icon/flexibility.svg',
		imgActive: '/assets/icon/flexibility_contrast.svg',
		description: `is our ability to effectively cope with changes in our environment,
		 particularly being able to return to a state of well-being after stressful or unexpected events.`,
		scoreSummary: {
			languishing: `You’re feeling frustrated when things don’t go as they should, being drained
			by changes to routine or schedule, and having a hard time managing day-to-day
			responsibilities when stressed out. You rarely experience things that help you grow
			and challenge how you think or act.`,
			functioning: `Changes to routine are consistently stressful, you feel like your head
			is “just above water” when it comes to managing your day-to-day responsibilities.
			Sometimes you experience things that help you grow and challenge how you think or
			act, but don’t regularly seek them out.`,
			flourishing: `You regularly feel comfortable with whatever your day or week throws at you,
			good or bad. You seek out and experience things that help you grow and challenge
			how you think or act.`
		}
	},
	{
		key: 'happiness',
		title: 'Happiness',
		img: '/assets/icon/happiness.svg',
		imgActive: '/assets/icon/happiness_contrast.svg',
		description: 'is how frequently we feel positive emotions.',
		scoreSummary: {
			languishing: `You’re feeling lethargic, finding it very difficult enjoying activities that
			usually make you happy, and having difficulty motivating yourself to participate
			in joyful experiences.`,
			functioning: `Some activities are less joyful than they used to be,
			noticing less “happy” or “good” days than usual.`,
			flourishing: `You’re regularly feeling excited about your day, frequently being able
			to find moments of calm and peacefulness, and confident in handling
			the demands of your daily life.`
		}
	},
	{
		key: 'confidence',
		title: 'Confidence',
		img: '/assets/icon/confidence.svg',
		imgActive: '/assets/icon/confidence_contrast.svg',
		description: 'Confidence is how you value and perceive who you are, what you do, and why you exist.',
		scoreSummary: {
			languishing: `You have a difficult time feeling good about aspects of yourself,
			be it your personality, appearance, or how your life is turning out.
			Expressing your opinions can be difficult, especially when they differ from the majority.`,
			functioning: `You regularly experience self-consciousness about aspects of who you are or
			the beliefs you have. There are days where you feel confident, but it’s not consistent.`,
			flourishing: `You feel confident in who you are, how you behave, and the beliefs you have.
			When something shakes your confidence, you’re able to recover quickly.`
		}
	},
	{
		key: 'presence',
		title: 'Presence',
		img: '/assets/icon/presence.svg',
		imgActive: '/assets/icon/presence_contrast.svg',
		description: `is the ability to live in the moment, savoring and enjoying the activities of life;
		 being absorbed in and excited by life activities.`,
		scoreSummary: {
			languishing: `It’s difficult to feel interested or present in the daily activities of your life.
			You regularly feel like you’re not in control of your life, as if you’re just along for the ride.`,
			functioning: `Sometimes you feel interested and present in your daily activities,
			but it comes and goes. There are days where your life feels outside of your control,
			and you’re just along for the ride. Your energy to take on the day isn’t consistent.`,
			flourishing: `You frequently feel interested and present in the daily activities of your life.
			Generally, you feel in control of your life, experiencing and making intentional
			choices about what you want. You regularly feel energized to take on the day.`
		}
	},
	{
		key: 'purpose',
		title: 'Purpose',
		img: '/assets/icon/purpose.svg',
		imgActive: '/assets/icon/purpose_contrast.svg',
		description: `is knowledge of and intention to do things that are both personally meaningful
		and make a positive improvement to the lives of others.`,
		scoreSummary: {
			languishing: `You’re not generally satisfied with how your life is going.
			It feels like you’re wandering through life, waiting for something to guide you rather
			than being in charge. Your work frequently does not feel meaningful to you.`,
			functioning: `There are some days where your life doesn’t feel like it has a
			strong sense of purpose. There are days where you don’t feel as though your work
			brings meaning or joy, and while you sometimes contribute worthwhile things to
			your communities, it’s not as frequent as you’d like.`,
			flourishing: `You’re happy with how your life works: the things you do are meaningful and important,
			you feel a sense of direction and purpose when you make choices about your life,
			and you feel confident that you can make choices about your life with a sense of purpose.`
		}
	}
];
