import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { UserInformationGuard } from './core/guards/user-information.guard';
import { AssessmentGuard } from './core/guards/assessment.guard';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'home',
		pathMatch: 'full'
	},
	// {
	// 	path: 'splash',
	// 	loadChildren: () => import('./splash/splash.module').then(m => m.SplashPageModule)
	// },
	{
		path: 'compass',
		loadChildren: () => import('./compass/compass.module').then(m => m.CompassPageModule)
	},
	{
		path: 'auth',
		loadChildren: () => import('./auth/auth.module').then(m => m.AuthPageModule)
	},
	{
		path: 'user-information',
		loadChildren: () => import('./user-information/user-information.module').then(m => m.UserInformationPageModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'home',
		loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
		canLoad: [AuthGuard, UserInformationGuard]
	},
	{
		path: 'profile',
		redirectTo: 'home/profile'
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
